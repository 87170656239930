@font-face {
  font-family: 'Inter';
  src:
    local('Inter ExtraLight'),
    local('Inter-ExtraLight'),
    url('./assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter Light'),
    local('Inter-Light'),
    url('./assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter Regular'),
    local('Inter-Regular'),
    url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter Medium'),
    local('Inter-Medium'),
    url('./assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter SemiBold'),
    local('Inter-SemiBold'),
    url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src:
    local('Inter Bold'),
    local('Inter-Bold'),
    url('./assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html,
body,
#root {
  height: 100%;
}
